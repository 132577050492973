document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    hamburger_menu();
    close_survey();
    navigation_bg();
    data_images();
    subtitleFadeIn(2600);
    scrollDownArrows(5000);

    window.onload = function () {
      calcContactWidths();

      pageloader();
    };
  }
};

// Helper
/* Scrolldown arrows */
function scrollDownArrows(timeout) {
  if (document.querySelector(".scrolldown")) {
    document.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 10) {
        document.querySelector(".scrolldown").classList.remove("show");
      } else {
        document.querySelector(".scrolldown").classList.add("show");
      }
    });

    if (document.documentElement.scrollTop <= 10) {
      setTimeout(() => {
        document.querySelector(".scrolldown").classList.add("show");
      }, timeout);
    }
  }
}

/* Fixed Contact Helper */
function calcContactWidths() {
  const phoneLength = document
    .getElementById("phone")
    .getBoundingClientRect().width;
  const phoneEl = document.querySelector(".phone a");
  phoneEl.style.marginRight = `-${phoneLength}px`;

  const mailLength = document
    .getElementById("mail")
    .getBoundingClientRect().width;
  const mailEl = document.querySelector(".mail a");
  mailEl.style.marginRight = `-${mailLength}px`;

  setTimeout(() => {
    document.querySelector(".fixed-elements").classList.add("show");
  }, 3200);
}

/* Hero Typing */
let i = 0;
let txt = document.getElementById("hiddenTyping")
  ? document.getElementById("hiddenTyping").innerHTML
  : "";
const speed = 50;

function typeWriter() {
  if (i < txt.length) {
    document.getElementById("typing").innerHTML += txt.charAt(i);
    i++;
    setTimeout(typeWriter, speed);
  }
}

function subtitleFadeIn(timeout) {
  if (document.getElementById("hero-subtitle")) {
    setTimeout(() => {
      document.getElementById("hero-subtitle").classList.add("fade");
    }, timeout);
  }
}

/* Language Switcher */
const lngSwitchBtns = document.querySelectorAll(".lngSwitchBtn");
if (lngSwitchBtns.length > 0) {
  for (let i = 0; i < lngSwitchBtns.length; i++) {
    const lngButton = lngSwitchBtns[i];

    lngButton.addEventListener("click", (e) => {
      e.preventDefault();
      const lng = lngButton.dataset.lng;
      document.cookie = `i18next=${lng}; path=/; max-age=31536000`;

      window.setTimeout(() => {
        location.reload();
      }, 10);
    });
  }
}

/* SLIDE UP */
let slideUp = (target, duration = 500) => {
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.boxSizing = "border-box";
  target.style.height = target.offsetHeight + "px";
  target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = "none";
    target.style.removeProperty("height");
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
    //alert("!");
  }, duration);
};

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {
  target.style.removeProperty("display");
  let display = window.getComputedStyle(target).display;
  if (display === "none") display = "block";
  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = "border-box";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.height = height + "px";
  target.style.removeProperty("padding-top");
  target.style.removeProperty("padding-bottom");
  target.style.removeProperty("margin-top");
  target.style.removeProperty("margin-bottom");
  window.setTimeout(() => {
    target.style.removeProperty("height");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};

// -------------------------------------------------
// -----------  ANCHOR NAVIGATION ------------------
// -------------------------------------------------

var nav = new SlideNav({ offset: window.innerWidth > 1040 ? 119 : 0 });

// -------------------------------------------------
// -----------  GLIDER ------------------
// -------------------------------------------------
if (document.querySelector(".glide")) {
  new Glide(".glide", {
    type: "carousel",
    focusAt: 1,
    autoplay: 4000,
    hoverpause: true,
    startAt: 0,
    perView: 3,
    breakpoints: {
      900: {
        perView: 2,
        focusAt: 1,
      },
      600: {
        perView: 1,
        focusAt: 0,
      },
    },
  }).mount();
}

// -----------------------------------------------------
// ---------------   PAGELOADER    --------------------
// -----------------------------------------------------
function pageloader() {
  "use strict";
  //check scrollposition and add opened status to navigation
  if (document.documentElement.scrollTop > 0) {
    document.querySelector(".navigation .topbar_inner").classList.add("opened");
  }

  var speed = 50;
  setTimeout(function () {
    document.body.classList.add("loaded");
  }, speed + 100);
  setTimeout(function () {
    document.querySelector(".navigation").classList.add("loaded");
    typeWriter();
  }, speed + 200);
}

// -----------------------------------------------------
// ---------------   DATA IMAGES    --------------------
// -----------------------------------------------------

function data_images() {
  "use strict";

  var data = document.querySelectorAll("*[data-img-url]");

  for (var i = 0; i < data.length; i++) {
    var url = data[i].getAttribute("data-img-url");
    data[i].style.backgroundImage = "url(" + url + ")";
  }
}

// -----------------------------------------------------
// ---------------   CLOSE SURVEY    --------------------
// -----------------------------------------------------

function close_survey() {
  "use strict";

  var closeBtn = document.querySelector("#closeSurvey");
  var surveyBanner = document.querySelector("#survey");
  var surveyBtn = document.querySelector("#surveyBtn");

  closeBtn.addEventListener("click", function () {
    if (surveyBanner.classList.contains("show")) {
      surveyBanner.classList.remove("show");
    }
    return false;
  });

  surveyBtn.addEventListener("click", function () {
    // URL der Website, die du öffnen möchtest
    var url = "https://www.survio.com/survey/d/X4E5M1F5Y4C7H8W1S";

    // Öffne die URL in einem neuen Tab
    window.open(url, "_blank");
  });
}

// -----------------------------------------------------
// ---------------   MOBILE MENU    --------------------
// -----------------------------------------------------

function hamburger_menu() {
  "use strict";

  var hamburger = document.querySelector(".hamburger");
  var mobileMenu = document.querySelector(".mobile_navigation .dropdown");
  var mobileMenuLink = document.querySelector(
    ".mobile_navigation .dropdown .dropdown_inner ul li a"
  );

  hamburger.addEventListener("click", function () {
    if (hamburger.classList.contains("is-active")) {
      hamburger.classList.remove("is-active");
      slideUp(mobileMenu, 1000);
    } else {
      hamburger.classList.add("is-active");
      slideDown(mobileMenu, 1000);
    }
    return false;
  });

  mobileMenuLink.addEventListener("click", function () {
    hamburger.classList.remove("is-active");
    slideUp(mobileMenu, 1000);
    return false;
  });
}

// -----------------------------------------------------
// --------------   TOPBAR BACKGROUND    ---------------
// -----------------------------------------------------

function navigation_bg() {
  "use strict";

  window.addEventListener("scroll", function () {
    var topbar = document.querySelector(".navigation .topbar_inner");

    if (window.pageYOffset >= 100) {
      topbar.classList.add("opened");
    } else {
      topbar.classList.remove("opened");
    }
  });
}

// Waypoints

const items = document.querySelectorAll(".item");
if (items.length) {
  items.forEach((item) => {
    new Waypoint({
      element: item,
      handler: function () {
        items.forEach((oneitem) => {
          oneitem.classList.remove("active");
        });
        item.classList.add("active");
      },
      offset: "50%",
    });
  });
}

const contactArea = document.querySelector("#contact");
if (contactArea) {
  new Waypoint({
    element: contactArea,
    handler: function () {
      const marks = contactArea.querySelectorAll("mark");
      marks.forEach((mark) => {
        mark.classList.add("active");
      });
    },
    offset: "30%",
  });
}

// End Waypoints

const contact_btn = document.getElementById("contact_btn");
const modal = document.getElementById("modal");
const closeBtn = document.querySelector(".close");

if (contact_btn) {
  //TODO: append form to modal

  contact_btn.addEventListener("click", () => {
    if (modal) {
      modal.classList.add("opened");
    }
  });
}

if (closeBtn) {
  closeBtn.addEventListener("click", () => {
    if (modal) {
      modal.classList.remove("opened");
    }
  });
}
